import { __ } from '@/lib/utils'
import { cn } from 'ui'

export default function Loading({ className }: { className?: string }) {
    return (
        <div
            className={cn(
                'flex items-center justify-center rounded-xl border border-muted bg-muted/20 text-sm italic drop-shadow saturate-50',
                className,
            )}
        >
            {__('Loading...')}
        </div>
    )
}
