import { Avatar, Badge, Card, cn } from 'ui'
import { Container } from '@/components/container'
import { TextHoverEffect } from './text-hover-effect'
import { Heading } from 'ui'
import { __ } from '@/lib/utils'
import { Link } from 'react-aria-components'
import { Icon } from './icons'
import { SeriesData } from '@/types/generated'

export function HomeSperator({ className, series }: { className?: string; series: Array<SeriesData> }) {
    const appName = import.meta.env.VITE_APP_NAME

    return (
        <>
            <div className={cn('magicpattern mb-2 mt-14 w-full shadow-md lg:mb-6', className)}>
                <Container>
                    <div className="flex h-32 w-96 items-center justify-between py-2 lg:h-44">
                        <div className="flex flex-col gap-y-2">
                            <Heading className="font-poppins font-medium text-white" level={1}>
                                {__('Yuk, kita bersama kembangkan kemampuan!')}
                            </Heading>
                            <Link
                                className="group flex w-fit cursor-pointer items-center gap-x-2 text-white duration-200 hover:text-zinc-200"
                                href="/series"
                            >
                                {__('Lihat semua seri sekarang')}
                                <Icon
                                    iconName="ArrowRight"
                                    className="fill-white duration-200 group-hover:fill-zinc-200"
                                />
                            </Link>
                        </div>
                    </div>
                </Container>
            </div>
            <div>
                <Container>
                    <Heading level={3}>
                        {__('Rasakan serunya belajar secara seri dan nikmati semua manfaatnya!')}
                    </Heading>
                    <div className="mb-6 mt-4 grid grid-cols-1 gap-x-10 gap-y-4 md:mb-8 md:grid-cols-2 lg:mb-10 lg:mt-8 lg:gap-y-8">
                        <div className="flex space-x-4">
                            <div className="flex h-10 w-10 items-center justify-center rounded-full bg-muted p-3">
                                <Icon iconName="Journals" size="2xl" />
                            </div>
                            <div className="">
                                <div className="font-medium">{__('Terstruktur')}</div>
                                <div className="text-justify text-xs font-light text-muted-fg">
                                    {__(
                                        'Pembelajaran seri disusun dengan alur yang rapi, jadi semua aspek penting dari topik pasti kebahas. Ini bikin kamu nggak bingung atau ketinggalan info, karena tiap bagian saling melengkapi.',
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="flex space-x-4">
                            <div className="flex h-10 w-10 items-center justify-center rounded-full bg-muted p-3">
                                <Icon iconName="Bullseye" size="2xl" />
                            </div>
                            <div className="">
                                <div className="font-medium">{__('Fokus')}</div>
                                <div className="text-justify text-xs font-light text-muted-fg">
                                    {__(
                                        'Belajar secara seri bikin fokus kamu tetap terjaga karena tiap sesi berhubungan sama yang sebelumnya. Ini bikin kamu nggak gampang hilang konsentrasi atau kelewatan detail penting yang sering terjadi kalau belajar topik secara acak.',
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="flex space-x-4">
                            <div className="flex h-10 w-10 items-center justify-center rounded-full bg-muted p-3">
                                <Icon iconName="Cpu" size="2xl" />
                            </div>
                            <div className="">
                                <div className="font-medium">{__('Mendalam')}</div>
                                <div className="text-justify text-xs font-light text-muted-fg">
                                    {__(
                                        'Dengan belajar secara seri, materinya disajikan step by step. Ini bikin kamu bisa paham lebih dalam karena tiap topik dibahas lebih detail.',
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="flex space-x-4">
                            <div className="flex h-10 w-10 items-center justify-center rounded-full bg-muted p-3">
                                <Icon iconName="Lightbulb" size="2xl" />
                            </div>
                            <div className="">
                                <div className="font-medium">{__('Nempel')}</div>
                                <div className="text-justify text-xs font-light text-muted-fg">
                                    {__(
                                        'Karena materinya disusun dengan urutan yang pas, informasi jadi lebih gampang diingat dan dipraktekin. Belajar secara seri bikin ingatan dan pemahaman kamu jadi lebih nempel dan tahan lama.',
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="flex space-x-4">
                            <div className="flex h-10 w-10 items-center justify-center rounded-full bg-muted p-3">
                                <Icon iconName="Stars" size="2xl" />
                            </div>
                            <div className="">
                                <div className="font-medium">{__('Gratis')}</div>
                                <div className="text-justify text-xs font-light text-muted-fg">
                                    {__(
                                        'Semua seri ini gratis, jadi kamu bisa belajar tanpa pusing soal biaya. Nikmati setiap sesi tanpa harus khawatir keluar uang, dan ambil semua manfaatnya tanpa beban tambahan!',
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <section id="series">
                        <Card.Header className="px-0">
                            <div className="text-base font-medium text-fg md:text-lg">{__('Seri Terbaru')}</div>
                            <div className="text-sm text-muted-fg">
                                {__('Kumpulan seri paling baru yang ada disini.')}
                            </div>
                        </Card.Header>
                        <div className="grid flex-1 grid-cols-1 gap-6 md:grid-cols-3">
                            {series.map((item) => (
                                <div key={item.id}>
                                    <Link href={route('series.show', [item.slug])} className="relative h-56 w-full">
                                        <Badge
                                            className="absolute left-2 top-2 z-10 flex w-12 justify-center"
                                            shape="circle"
                                            intent="dark"
                                        >
                                            {(item.user_articles_count / item.series_details_count) * 100}%
                                        </Badge>
                                        <img
                                            alt={item.title}
                                            loading="lazy"
                                            src={item.thumbnail}
                                            className="h-56 w-full rounded-xl border object-cover object-center dark:brightness-90"
                                        />
                                    </Link>
                                    <div className="space-y-3 px-2 pt-3">
                                        <Link
                                            href={route('series.show', [item.slug])}
                                            className="text-md w-full pb-0.5 font-medium"
                                        >
                                            {item.title}
                                        </Link>
                                        <div className="flex justify-between">
                                            <div className="flex flex-row items-center space-x-2">
                                                <Avatar
                                                    size="small"
                                                    src={item.user.gravatar}
                                                    aria-label="Gravatar author"
                                                />
                                                <div className="text-sm text-muted-fg">{item.user.name}</div>
                                            </div>
                                            <Badge>{__('{n} Seri', { number: item.series_chapters_count })}</Badge>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </section>
                </Container>
            </div>
        </>
    )
}
